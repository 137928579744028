export default [
 
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/dashboard",
    icon: "cil-speedometer",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Broker Companies",
    to: "/broker_companies",
    icon: "cil-building",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Users",
    to: "/users",
    icon: "cil-user",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Properties",
    to: "/properties",
    icon: "cil-house",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Leads",
    to: "/leads",
    icon: "cil-speedometer",
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Subscriptions",
  //   to: "/subscriptions",
  //   icon: "cil-speedometer",
  // },
  {
    _tag: "CSidebarNavItem",
    name: "Promo Codes",
    to: "/promo_codes",
    icon: "cil-speedometer",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Push Notifications",
    to: "/push_notifications",
    icon: "cil-speedometer",
  },
  // Property Stats - Broker Companies Stats - User Stats - Financial Stats
];
