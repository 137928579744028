// @ts-nocheck
import React, { useContext, useState } from "react";
import { get } from "../../../utilis/requests";
import { AxiosResponse } from "axios";
import {
  axiosPatchWithToken,
  axiosPostWithToken,
  axiosRemoveWithToken,
  axiosGetWithToken,
} from "../../../utilis/requests";
import userContext from "../../../userStore/context";
import { numberWithCommas } from "../../../utilis/numberSeparator";
import { dateSplitter, longDateSplitter } from "../../../utilis/objectHandlers";

interface IAddCompanyPayload {
  name: string;
  measureUnit: string;
  currency: string;
  admin_id: string;
  plan: { type: "CUSTOMIZED" | "FREE" | "STANDARD" | "PREMIUM" };
  paid: boolean;
  max_agents: number | string;
  countryByIPAddress: string;
  zipcode: string;
  address: string;
  description: string;
  phoneNumber: string;
  image: { url: string; public_id: "123456" };
  governrate_id: string;
  country_id: string;
}
const useGetProperties = () => {
  const { userState } = useContext(userContext);
  const [info, setInfo] = useState({
    total: 0,
    newCompany: 0,
    totalIncome: 0,
  });
  const [loading, setLoading] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState<any>([]);
  const [adminsListOnly, setAdminsList] = useState([]);
  const [measureUnits, setMeasureUnits] = useState<any>([]);
  const [currencies, setCurrencies] = useState<any>([]);

  const currencyOperation = React.useCallback(
    async (
      functionOperation: (...args: any) => Promise<AxiosResponse<any>>,
      callback?: (response: AxiosResponse) => void
    ) => {
      setLoading(true);
      try {
        const response: AxiosResponse = await functionOperation(
          `/brokercompany/currency/latest`,
          undefined,
          userState.token
        );
        if (response.status === 200) {
          const fun = callback ? callback : (...args: any) => {};
          fun(response);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [userState.token]
  );

  const measureUnitOperation = React.useCallback(
    async (
      functionOperation: (...args: any) => Promise<AxiosResponse<any>>,
      callback?: (response: AxiosResponse) => void
    ) => {
      setLoading(true);
      try {
        const response: AxiosResponse = await functionOperation(
          `/brokercompany/measure`,
          undefined,
          userState.token
        );
        if (response.status === 200) {
          const fun = callback ? callback : (...args: any) => {};
          fun(response);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [userState.token]
  );

  const usersOperation = React.useCallback(
    async (
      functionOperation: (...args: any) => Promise<AxiosResponse<any>>,
      callback?: (response: AxiosResponse) => void
    ) => {
      setLoading(true);
      try {
        const response: AxiosResponse = await functionOperation(
          `/superadmin/user`,
          undefined,
          userState.token
        );
        if (response.status === 200) {
          const fun = callback ? callback : (...args: any) => {};
          fun(response);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [userState.token]
  );

  const countriesOperation = React.useCallback(
    async (
      functionOperation: (...args: any) => Promise<AxiosResponse<any>>,
      callback?: (response: AxiosResponse) => void
    ) => {
      setLoading(true);
      try {
        const response: AxiosResponse = await functionOperation(
          `/country`,
          undefined,
          userState.token
        );
        if (response.status === 200) {
          const fun = callback ? callback : (...args: any) => {};
          fun(response);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [userState.token]
  );

  const companyOperation = React.useCallback(
    async (
      payload: IAddCompanyPayload,
      functionOperation: (...args: any) => Promise<AxiosResponse<any>>,
      query?: [string, unknown][],
      callback?: (response: AxiosResponse) => void
    ) => {
      setLoadingCompanies(true);
      try {
        const response: AxiosResponse = await functionOperation(
          `/superadmin/brokercompany`,
          payload,
          userState.token,
          query
        );
        if (response.status === 200) {
          const fun = callback ? callback : (...args: any) => {};
          fun(response);
          setLoadingCompanies(false);
        }
      } catch (error) {
      } finally {
      }
    },
    [userState.token]
  );

  // pending
  const addCompany = (
    payload: IAddCompanyPayload,
    callback = (...args: any): void => {}
  ) =>
    companyOperation(payload, axiosPostWithToken, [], (response) => {
      getCompanies();
      callback(response);
    });

  // pending
  const editCompany = (
    payload: IAddCompanyPayload,
    query: [string, unknown][],
    callback = (...args: any): void => {}
  ) => {
    if (payload && (payload.data as any)._id) {
      delete (payload.data as any)._id;
      delete (payload.data as any).__v;
      delete (payload.data as any).revoked;
      payload.data.governrate_id = payload.data.governrate_id.id;
      payload.data.country_id = payload.data.country_id.id;
      payload.data.admin_id = payload.data.admin_id.id;
    }
    companyOperation(payload.data, axiosPatchWithToken, query, (response) => {
      getCompanies();
      callback(response);
    });
  };

  // done
  const removeCompany = (payload: string) => {
    companyOperation({} as any, axiosRemoveWithToken, [["id", payload]], () => {
      getCompanies();
    });
  };

  const getAdminsOnly = React.useCallback(async () => {
    try {
      const response: AxiosResponse = await get(
        `/superadmin/useradminonly`,
        userState.token
      );

      if (response.status === 200) {
        setAdminsList(response.data);
      } else {
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  }, [setAdminsList, userState.token]);
  const getCompanies = React.useCallback(() => {
    companyOperation({} as any, axiosGetWithToken, [], (response) => {
      setData(response.data.data);
      setInfo({
        total: numberWithCommas(response.data.total),
        newCompany: numberWithCommas(response.data.newCompany),
        totalIncome: numberWithCommas(response.data.totalIncome),
      });

      const companiesRes = response.data.data.map((item: any) => {
        console.log(item, "item");
        return {
          _id: item?.data?._id ? item.data._id : "None",
          name: item?.data?.name ? item?.data.name : "None",
          phoneNumber: numberWithCommas(item?.data?.phoneNumber, {
            character: "-",
            each: 3,
          }),
          country_id: item?.data?.country_id
            ? item?.data?.country_id.label ?? "No"
            : "None",
          currency: item?.data?.currency ? item.data.currency : "None",
          leadsConnected: item.leadsOfCompany,
          propsConnected: item.propertiesOfCompany,
          usersCount: item?.agentUsersCount ? item.agentUsersCount : 0,
          createdAt: dateSplitter(
            item?.data?.createdAt ? item.data.createdAt : "None"
          ),
          email: item?.data?.admin_id?.email
            ? item.data.admin_id.email
            : "None",
          expDate: item?.subData && longDateSplitter(item.subData),
          status: item?.status ? item.status : "",
        };
      });
      const filterCompany = companiesRes.filter((item) => {
        return item._id !== "None";
      });
      setCompanies(filterCompany);
    });
  }, [companyOperation]);

  const [governorates, setGovernorates] = React.useState<any>([]);
  const [countries, setCountries] = React.useState<any>([]);
  const getCountries = React.useCallback(() => {
    countriesOperation(axiosGetWithToken, (response) => {
      setCountries(response.data);
    });
  }, [countriesOperation]);

  const [admins, setAdmins] = React.useState<any>([]);
  const getAdmins = React.useCallback(() => {
    usersOperation(axiosGetWithToken, (response) => {
      setAdmins(response.data.data);
    });
  }, [usersOperation]);

  const getMeasureUnits = React.useCallback(() => {
    measureUnitOperation(axiosGetWithToken, (response) => {
      setMeasureUnits(response.data);
    });
  }, [measureUnitOperation]);

  const getCurrencies = React.useCallback(() => {
    currencyOperation(axiosGetWithToken, (response) => {
      setCurrencies(response.data);
    });
  }, [currencyOperation]);

  React.useEffect(() => {
    // handleData();
    getCurrencies();
    getMeasureUnits();
    getCountries();
    getAdminsOnly();
    getCompanies();
  }, [
    getCompanies,
    getAdmins,
    getCountries,
    getCurrencies,
    getMeasureUnits,
    getAdminsOnly,
  ]);

  return {
    measureUnits,
    currencies,
    admins,
    loading,
    countries,
    governorates,
    setGovernorates,
    adminsListOnly,
    info,
    loadingCompanies,
    data,
    addCompany,
    editCompany,
    removeCompany,
    companies,
    getCompanies,
  };
};

export default useGetProperties;
