import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  CDataTable,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CButton,
  CRow,
  CCol,
  CWidgetIcon,
  CModalHeader,
  CModalBody,
  CForm,
  CFormGroup,
  CLabel,
  CInput,
  CModalFooter,
  CModal,
  CButtonGroup,
  CSpinner,
  CAlert,
  CSelect,
} from "@coreui/react";
import { FaEye } from "react-icons/fa";
import CIcon from "@coreui/icons-react";
import Select from "react-select";
import hooks from "./hooks/hooks";
import { validateEmail, validatePhone } from "../../utilis/validators";

// Name - Email - Number - Broker Company - Properties Added - Leads Added

interface user {
  name: string;
  email: string;
  tempPass: string;
  admin: string;
  role: string;
  confirmPass: string;
  joinedAt?: string;
  number: number | string;
}
const tableObject = {
  values: [10, 15, 20, 30, 40, 50],
};
const Users = () => {
  const {
    handleUserData,
    loadingUsers,
    userTotal,
    users: usersData,
    submitNewUserData,
    editUserData,
    deleteUser,
    companies,
    activeUsers,
    newUsers,
    error,
    setError,
    setShowModal,
    showModal,
    userState,
  } = hooks();
  const [editModal, setEditModal] = useState(false);
  const [enteredEffect, setEnteredEffect] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [selectedNewCompany, setSelectedNewCompany] = useState<any>({});
  const [newUserData, setNewUserData] = useState<user>({
    email: "",
    name: "",
    admin: "",
    confirmPass: "",
    tempPass: "",
    role: "",
    number: "",
    joinedAt: "",
  });
  const [viewModal, setViewModal] = useState(false);
  const submitNewUser = () => {
    setError(null);
    if (newUserData && !validateEmail(newUserData.email)) {
      setError("Please Enter a correct Email");
      return;
    }
    if (newUserData && !validatePhone(newUserData.number.toString())) {
      setError("Please Enter a correct Phone Number");
      return;
    }
    if (newUserData && Object.keys(selectedNewCompany).length === 0) {
      setError("Please enter the user company");
      return;
    }
    const payload = {
      name: newUserData?.name,
      email: newUserData?.email,
      companyId: selectedNewCompany.value,
      phoneNumber: newUserData?.number,
      password: "12345678",
      role: newUserData?.role,
    };
    if (editModal) editUserData(payload, selectedID);
    else {
      submitNewUserData(payload);
      handleUserData();
      setShowModal(false);
    }
  };
  const setUserRole = useCallback(
    (e) => {
      //@ts-ignore
      let selectedValue = document.getElementById("listAdd").value;
      setNewUserData((oldState: any) => ({
        ...oldState,
        role: selectedValue,
      }));
    },
    [setNewUserData]
  );
  const handleChange = React.useCallback(
    (name: string) => ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      setNewUserData((oldState: any) => ({
        ...oldState,
        [name]: value,
      }));
    },
    [setNewUserData]
  );

  const fields = [
    { key: "name", label: "Name", _style: { width: "25%" } },
    { key: "email", label: "Email", _style: { width: "15%" } },
    {
      key: "number",
      label: "Number",
      _style: { width: "15%" },
    },
    { key: "brokerCompany", label: "Broker Company", _style: { width: "15%" } },
    {
      key: "propertiesAdded",
      label: "Properties Added",
      _style: { width: "15%" },
    },
    { key: "joinedAt", label: "Joined At", _style: { width: "15%" } },
    { key: "leadsAdded", label: "Leads Added", _style: { width: "15%" } },
    {
      key: "actions",
      label: "Actions",
      _style: { width: "10%" },
      sorter: false,
      filter: false,
    },
  ];
  const location = useLocation();
  const toggle = (x: boolean, item?: any) => {
    setViewModal(false);
    if (item) {
      setNewUserData({
        email: item.email,
        name: item.name,
        role: item.role,
        admin: item.admin,
        confirmPass: "",
        tempPass: "",
        number: item.number,
      });
      setSelectedNewCompany({
        value: item.brokerCompanyID,
        label: item.brokerCompany,
      });
      setSelectedID(item.id);
    } else {
      setNewUserData({
        email: "",
        name: "",
        role: "",
        admin: "",
        confirmPass: "",
        tempPass: "",
        number: "",
      });
      setSelectedNewCompany({});
    }
    setEditModal(x);
    setShowModal((toggle) => !toggle);
  };
  useEffect(() => {
    //@ts-ignore
    if (location.state && usersData) {
      //@ts-ignore
      let item = usersData.find((item) => {
        return item.id === location.state;
      });
      if (!enteredEffect) {
        toggle(true, item);
        setEnteredEffect(true);
      }
    }
    // eslint-disable-next-line
  }, [location, usersData, enteredEffect, setEnteredEffect]);

  useEffect(() => {
    handleUserData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {}, [usersData, userTotal]);
  const deleteSelectedUser = useCallback(
    (id: string) => {
      deleteUser(id);
    },
    [deleteUser]
  );
  const openViewModal = (x: boolean, item: any) => {
    setViewModal(true);
    setNewUserData({
      email: item.email,
      name: item.name,
      role: item.role,
      admin: item.admin,
      confirmPass: "",
      tempPass: "",
      number: item.number,
    });
    setSelectedNewCompany({
      value: item.brokerCompanyID,
      label: item.brokerCompany,
    });
    setSelectedID(item.id);
    setEditModal(x);
    setShowModal((toggle) => !toggle);
  };

  const toggleRemoveModal = useCallback(() => {
    setShowRemoveModal((toggle) => !toggle);
  }, [setShowRemoveModal]);

  const removeUserCall = useCallback(() => {
    deleteSelectedUser(selectedID);
    toggleRemoveModal();
  }, [deleteSelectedUser, toggleRemoveModal, selectedID]);
  if (loadingUsers) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%", height: "100%" }}
      >
        <CSpinner
          color="#8C03F9"
          grow
          style={{ height: "4rem", width: "4rem", alignSelf: "center" }}
        />
      </div>
    );
  } else {
    return (
      <div>
        <CRow alignHorizontal="start" alignVertical="start">
          <CCol xl="4">
            <CWidgetIcon
              text="New Users last month"
              header={newUsers.toString()}
              color="primary"
            >
              <CIcon name={"cilSettings"} size={"xl"} />
            </CWidgetIcon>
          </CCol>
          <CCol xl="4">
            <CWidgetIcon text="Total Users" header={userTotal} color="primary">
              <CIcon name={"cilSettings"} size={"xl"} />
            </CWidgetIcon>
          </CCol>
          <CCol xl="4">
            <CWidgetIcon
              text="Active Users"
              header={activeUsers.toString()}
              color="primary"
            >
              <CIcon name={"cilSettings"} size={"xl"} />
            </CWidgetIcon>
          </CCol>
        </CRow>
        <CCard>
          <CCardHeader className="d-flex align-items-center justify-content-between">
            <CCardTitle>Users</CCardTitle>
            <CButton
              color="primary"
              variant="outline"
              shape="square"
              size="md"
              style={{
                justifySelf: "flex-end",
                alignItems: "flex-end",
              }}
              onClick={() => toggle(false)}
            >
              Add New User
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              fields={fields}
              columnFilter
              tableFilter
              hover
              sorter
              itemsPerPage={10}
              pagination
              itemsPerPageSelect={tableObject}
              items={usersData}
              scopedSlots={{
                actions: (item: {
                  id: string;
                  brokerCompanyID: string;
                  name: string;
                }) => (
                  <td className="py-2">
                    <CButtonGroup
                      color="primary"
                      shape="square"
                      size="sm"
                      onClick={() => {}}
                    >
                      <CButton
                        size="sm"
                        className="btn-info mr-1"
                        onClick={() => openViewModal(true, item)}
                      >
                        <FaEye />
                      </CButton>
                      <CButton
                        size="sm"
                        className="btn-primary mr-1"
                        onClick={() => toggle(true, item)}
                      >
                        <CIcon name="cil-pencil" />
                      </CButton>
                      <CButton
                        size="sm"
                        className="mr-1"
                        onClick={() =>
                          item.brokerCompanyID !== "None" &&
                          item.name !== "None"
                            ? window.open(
                                `https://beta.elbrokers.com/property-admin/:${item.id}_${userState.token}_${item.brokerCompanyID}_${item.name}`,
                                "_blank"
                              )
                            : false
                        }
                      >
                        <CIcon name="cil-building" />
                      </CButton>
                      <CButton
                        size="sm"
                        className="btn-danger mr-1"
                        onClick={() => {
                          setSelectedID(item.id);
                          toggleRemoveModal();
                        }}
                      >
                        <CIcon name="cil-trash" />
                      </CButton>
                    </CButtonGroup>
                  </td>
                ),
              }}
            />
          </CCardBody>
        </CCard>
        <CModal size="lg" show={showModal}>
          <CModalHeader closeButton>
            {editModal
              ? viewModal
                ? `Show User`
                : `Edit User`
              : `Add New User`}
          </CModalHeader>
          <CModalBody>
            <CForm action="" method="post">
              <CFormGroup>
                <CLabel htmlFor="nf-name">Name</CLabel>
                <CInput
                  type="name"
                  id="nf-name"
                  name="nf-name"
                  placeholder="Enter User Name ..."
                  autoComplete="name"
                  value={newUserData?.name}
                  onChange={handleChange("name")}
                  disabled={viewModal}
                />
              </CFormGroup>
              <CFormGroup>
                <CLabel htmlFor="nf-email">Email</CLabel>
                <CInput
                  type="email"
                  id="nf-email"
                  name="nf-email"
                  placeholder="Enter User Email ..."
                  autoComplete="email"
                  value={newUserData?.email}
                  onChange={handleChange("email")}
                  disabled={viewModal}
                />
              </CFormGroup>
              <CFormGroup>
                <CLabel htmlFor="nf-role">Role</CLabel>
                <CSelect
                  id="listAdd"
                  name="nf-discountType"
                  value={newUserData?.role}
                  onChange={setUserRole}
                >
                  <option selected>Open this select menu</option>
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                </CSelect>
              </CFormGroup>
              <CFormGroup>
                <CLabel htmlFor="nf-role">Company</CLabel>
                <Select
                  name="nf-role"
                  options={companies}
                  placeholder="Select User Company"
                  isSearchable
                  defaultValue="Select User Company"
                  value={selectedNewCompany}
                  onChange={setSelectedNewCompany}
                  isDisabled={viewModal}
                />
              </CFormGroup>
              <CFormGroup>
                <CLabel htmlFor="nf-mobile_phone">Phone Number</CLabel>
                <CInput
                  type="tel"
                  id="nf-mobile_phone"
                  name="nf-mobile_phone"
                  placeholder="Enter User Phone Number ..."
                  autoComplete="tel"
                  value={newUserData?.number}
                  onChange={handleChange("number")}
                  disabled={viewModal}
                />
              </CFormGroup>
            </CForm>
            {error && <CAlert color="warning">{error}</CAlert>}
          </CModalBody>
          <CModalFooter>
            {!viewModal && (
              <CButton
                color="primary"
                variant="outline"
                onClick={submitNewUser}
              >
                {editModal ? `Edit User` : `Add User`}
              </CButton>
            )}
            <CButton
              color="secondary"
              onClick={() => toggle(editModal)}
              variant="outline"
            >
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>

        <CModal size="lg" show={showRemoveModal} onClose={toggleRemoveModal}>
          <CModalHeader closeButton>Warning</CModalHeader>
          <CModalBody>
            <CLabel htmlFor="nf-mobile_phone">
              Are you sure you want to remove this property?
            </CLabel>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={toggleRemoveModal}
              variant="outline"
            >
              No
            </CButton>
            <CButton color="primary" onClick={removeUserCall} variant="outline">
              Yes
            </CButton>
          </CModalFooter>
        </CModal>
      </div>
    );
  }
};

export default Users;
