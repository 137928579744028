import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { useHistory } from "react-router-dom";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CDataTable,
  CForm,
  CFormGroup,
  CImg,
  CInput,
  CInputFile,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CSpinner,
  CSwitch,
  CTextarea,
  CWidgetIcon,
} from "@coreui/react";
import { FaEye } from "react-icons/fa";
import CIcon from "@coreui/icons-react";

import GetCompanyHook from "./hooks/getCompaniesHook";
import React from "react";
import { constructObj } from "../../utilis/objectHandlers";

const { useState, useCallback } = React;

// const subscriptions = [
//   { value: "free", label: "Free" },
//   { value: "team", label: "Team" },
//   { value: "prof", label: "Professional" },
//   { value: "enterprise", label: "Enterprise" },
// ];

const BrokerCompanies = () => {
  const {
    currencies,
    measureUnits,
    countries,
    governorates,
    setGovernorates,
    loadingCompanies,
    data,
    info,
    companies,
    removeCompany,
    editCompany,
    addCompany,
    adminsListOnly,
  } = GetCompanyHook();
  const { push } = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [adminsListArray, setAdminsListArray] = useState([]);
  const [selectedID, setSelectedID] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  // const [date, setDate] = useState<any>({});

  // const [subscriptionValue, setSubscriptionValue] = useState<any>({});

  const [form, setForm] = useState<any>({
    name: "",
    measureUnit: "",
    currency: "",
    admin_id: "",
    plan: { type: "FREE" },
    paid: true,
    max_agents: 0,
    countryByIPAddress: "",
    zipcode: "",
    address: "",
    description: "",
    phoneNumber: "",
    image: { url: "", public_id: "123456" },
    governrate_id: "",
    country_id: "",
  });

  const onChangeHandler = React.useCallback(
    (event: any) => {
      const names: any = event.currentTarget.name.split(".");
      let temp: any = { ...form.data };

      if (names.length > 1) {
        temp = constructObj(form.data, names, event.currentTarget.value);
      } else {
        if (names === "governrate_id") {
          let pickedGover = event.currentTarget.value;
          temp = {
            ...temp,
            [event.currentTarget.name]: pickedGover,
          };
        }
        temp = {
          ...temp,
          [event.currentTarget.name]: event.currentTarget.value,
        };
      }
      setForm({
        ...form,
        data: temp,
      });
    },
    [form, setForm]
  );

  const fields = [
    // { key: "id", _style: { display: "none" } },
    { key: "name", label: "Company Name", _style: { width: "15%" } },
    { key: "phoneNumber", label: "Phone Number", _style: { width: "15%" } },
    { key: "email", label: "Email", _style: { width: "15%" } },
    { key: "createdAt", label: "Joined At", _style: { width: "10%" } },
    // {key:""},
    { key: "country_id", label: "Country", _style: { width: "10%" } },
    {
      key: "currency",
      label: "Currency",
      _style: { width: "5%" },
    },

    {
      key: "usersCount",
      label: "No. Of Users",
      _style: { width: "7%" },
    },
    {
      key: "status",
      label: "Status",
      _style: { width: "7%" },
    },
    {
      key: "expDate",
      label: "Expiry Date",
      _style: { width: "13%" },
    },
    // { key: "members", label: "Members", _style: { width: "15%" } },
    // { key: "properties", label: "Properties", _style: { width: "15%" } },
    // { key: "income", label: "Income", _style: { width: "15%" } },
    {
      key: "actions",
      label: "Actions",
      _style: { width: "10%" },
      sorter: false,
      filter: false,
    },
  ];

  const toggle = React.useCallback(() => {
    setForm({
      name: "",
      measureUnit: "",
      currency: "",
      admin_id: "",
      plan: { type: "FREE" },
      paid: true,
      max_agents: 0,
      countryByIPAddress: "",
      zipcode: "",
      address: "",
      description: "",
      phoneNumber: "",
      image: { url: "", public_id: "123456" },
      governrate_id: "",
      country_id: "",
    });
    setShowModal((toggle) => {
      setIsEdit(!toggle === false);
      setIsView(!toggle === false);
      return !toggle;
    });
    //  handleAdminsList();
  }, [setForm, setShowModal, setIsEdit, setIsView]);
  const toggleRemoveModal = useCallback(
    (id) => {
      setSelectedID(id);
      setShowRemoveModal((toggle) => !toggle);
    },
    [setShowRemoveModal, setSelectedID]
  );

  const removeBrokerCall = useCallback(() => {
    removeCompany(selectedID);
    setShowRemoveModal((toggle) => !toggle);
  }, [selectedID, removeCompany, setShowRemoveModal]);
  React.useEffect(() => {
    const country = countries.find(
      (country: any) => country.id === form.country_id
    );

    if ((!isView && !isEdit) || !country) {
      return;
    }
    setForm((old: any) => {
      return {
        ...old,
        country_id: form.country_id ?? "",
        governrate_id: form.governrate_id ?? "",
      };
    });
    setGovernorates(country.governorates);
  }, [
    adminsListOnly,
    isEdit,
    form.country_id,
    form.governrate_id,
    isView,
    countries,
    setGovernorates,
  ]);
  const _renderSelectCountryIP = React.useMemo(() => {
    return (
      <select
        className="form-control"
        disabled={isView}
        name="country_id"
        onChange={(event: any) => {
          let pickedCountry = event.currentTarget.value;
          setForm((old: any) => {
            let temp = old.data;
            temp = {
              ...temp,
              countryByIPAddress: pickedCountry,
            };
            return {
              ...old,
              data: temp,
            };
          });
        }}
      >
        <option value={""} key={""}>
          None
        </option>
        {countries.map((c: any) => {
          return (
            <option value={c.label} key={c.label}>
              {c.label}
            </option>
          );
        })}
      </select>
    );
  }, [isView, countries, setForm]);
  const _renderSelectCountry = React.useMemo(() => {
    return (
      <select
        className="form-control"
        disabled={isView}
        name="country_id"
        onChange={(event: any) => {
          const country = countries.find(
            (country: any) => country.id === event.currentTarget.value
          );
          let pickedCountry = event.currentTarget.value;

          setForm((old: any) => {
            let temp = { ...form.data };
            temp = {
              ...temp,
              country_id: pickedCountry,
              governrate_id: "",
            };
            return {
              ...form,
              data: temp,
            };
          });
          setGovernorates(country.governorates);
          if (country.governorates.length === 1) {
            let temp: any = { ...form.data };
            temp = {
              ...temp,
              governrate_id: country.governorates[0].id,
            };
            setForm({
              ...form,
              data: temp,
            });
          }
        }}
      >
        <option value={""} key={""}>
          {form.data?.country_id ? form.data.country_id.label : "None"}
        </option>
        {countries.map((c: any) => {
          return (
            <option value={c.id} key={c.id}>
              {c.label}
            </option>
          );
        })}
      </select>
    );
  }, [isView, countries, setGovernorates, setForm, form]);

  const handleNav = React.useCallback(
    (userItem: any) => {
      push("/users", userItem.currentTarget.value);
    },
    [push]
  );
  const handleNavToProps = React.useCallback(
    (userItem: any) => {
      push("/properties", userItem.currentTarget.value);
    },
    [push]
  );
  const handleNavToLeads = React.useCallback(
    (userItem: any) => {
      push("/leads", userItem.currentTarget.value);
    },
    [push]
  );
  const handleActiveUsersList = React.useMemo(() => {
    if (isView || isEdit) {
      return (
        <CCol>
          <CLabel htmlFor="activeUsers">Active Users</CLabel>
          <br />
          <select
            className="form-control"
            onChange={handleNav}
            // onSelect={handleNav}
          >
            <option disabled selected>
              {"List Of All Users"}
            </option>
            {form.agentUsers?.map((a: any) => {
              return (
                <option value={a.id} key={a.id}>
                  {a.email}
                </option>
              );
            })}
          </select>
        </CCol>
      );
    }
  }, [isEdit, isView, handleNav, form]);

  const _renderModal = React.useMemo(() => {
    return (
      <CModal size="lg" show={showModal} onClose={toggle}>
        <CModalHeader closeButton>Company Details</CModalHeader>
        <CModalBody>
          <CForm action="" method="post">
            <CFormGroup>
              <CLabel htmlFor="name">Company Name</CLabel>
              <CInput
                onChange={onChangeHandler}
                type="text"
                disabled={isView}
                name="name"
                placeholder={form.data?.name ? form.data.name : "not entered"}
              />
            </CFormGroup>

            <CRow>
              <CCol>
                <CLabel htmlFor="admin_id">Admin</CLabel>
                <br />
                <select
                  className="form-control"
                  name="admin_id"
                  onChange={!isView && !isEdit ? onChangeHandler : handleNav}
                  // onSelect={handleNav}
                >
                  <option disabled selected>
                    {"List Of All Admins"}
                  </option>

                  {adminsListArray?.map((a: any) => {
                    return (
                      <option value={a.id} key={a.id}>
                        {a.email}
                      </option>
                    );
                  })}
                </select>
              </CCol>
              {handleActiveUsersList}
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="phoneNumber">Phone Number</CLabel>
                  <CInput
                    onChange={onChangeHandler}
                    type="name"
                    disabled={isView}
                    name="phoneNumber"
                    value={form.data?.phoneNumber ? form.data.phoneNumber : " "}
                    placeholder="+201211331132"
                  />
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CLabel htmlFor="admin_id">Properties</CLabel>
                <br />
                <select
                  className="form-control"
                  name="admin_id"
                  onChange={handleNavToProps}
                  // onSelect={handleNav}
                >
                  <option disabled selected>
                    {"List Of All Properties"}
                  </option>

                  {form.propertiesOfCompany?.map((a: any) => {
                    return (
                      <option value={a.id} key={a.id}>
                        {a.general?.property_name
                          ? a.general.property_name
                          : "Not Entered"}
                      </option>
                    );
                  })}
                </select>
              </CCol>
              <CCol>
                <CLabel htmlFor="admin_id">Leads</CLabel>
                <br />
                <select
                  className="form-control"
                  name="admin_id"
                  onChange={handleNavToLeads}
                  // onSelect={handleNav}
                >
                  <option disabled selected>
                    {"List Of All Leads"}
                  </option>

                  {form.leadsOfCompany?.map((a: any) => {
                    return (
                      <option value={a.id} key={a.id}>
                        {a.leadDetails.fullName
                          ? a.leadDetails.fullName
                          : "Not Entered"}
                      </option>
                    );
                  })}
                </select>
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="paid">paid</CLabel>
                  <br />
                  <CSwitch
                    color={"primary"}
                    disabled={isView}
                    checked={form.data?.paid ? form.data?.paid : false}
                    onChange={(ev) => {
                      onChangeHandler({
                        currentTarget: {
                          value: ev.currentTarget.checked,
                          name: "paid",
                        },
                      });
                    }}
                  />
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="image.url">Company Logo</CLabel>
                  <CInputFile
                    hidden={isView}
                    onChange={onChangeHandler}
                    disabled={isView}
                    type="file"
                    name="image.url"
                    src={form.data?.image?.url ?? ""}
                    accept="image/*"
                  />
                  <br />
                  <CImg
                    htmlFor="logo"
                    width={100}
                    height={100}
                    hidden={!isView}
                    src={form.image?.url ?? ""}
                  />
                </CFormGroup>
              </CCol>
            </CRow>
            <CFormGroup>
              <CLabel htmlFor="description">About Company</CLabel>
              <CTextarea
                name="description"
                disabled={isView}
                onChange={onChangeHandler}
                value={
                  form.data?.description ? form.data.description : "Not Entered"
                }
                placeholder="Enter About Company ..."
              />
            </CFormGroup>
            <CRow>
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="country">Country By IP</CLabel>
                  <br />
                  {_renderSelectCountryIP}
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="country_id">Country</CLabel>
                  <br />
                  {_renderSelectCountry}
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="governrate_id">Governorate</CLabel>
                  <br />
                  <select
                    className="form-control"
                    disabled={isView}
                    name="governrate_id"
                    onChange={(event: any) => {
                      onChangeHandler(event);
                    }}
                  >
                    <option value={""} key={""}>
                      {form.data?.governrate_id
                        ? form.data.governrate_id.label
                        : "None"}
                    </option>
                    {governorates.map((c: any) => {
                      return (
                        <option value={c.id} key={c.id}>
                          {c.label}
                        </option>
                      );
                    })}
                  </select>
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="address">Address</CLabel>
                  <CInput
                    onChange={onChangeHandler}
                    type="name"
                    disabled={isView}
                    name="address"
                    value={form.data?.address ? form.data.address : ""}
                    placeholder="Enter Property Address ..."
                  />
                </CFormGroup>
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="measureUnit">Measure Unit</CLabel>
                  <select
                    className="form-control"
                    disabled={isView}
                    name="measureUnit"
                    value={form.data?.measureUnit ? form.data?.measureUnit : ""}
                    onChange={(event: any) => {
                      onChangeHandler(event);
                    }}
                  >
                    <option value={""} key={""}>
                      None
                    </option>
                    {measureUnits.map((m: any, index: number) => {
                      return (
                        <option value={m.value} key={index}>
                          {m.label}
                        </option>
                      );
                    })}
                  </select>
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="currency">Currencies</CLabel>
                  <select
                    className="form-control"
                    disabled={isView}
                    name="currency"
                    value={form.data?.currency ? form.data?.currency : ""}
                    onChange={(event: any) => {
                      onChangeHandler(event);
                    }}
                  >
                    <option value={""} key={""}>
                      None
                    </option>
                    {currencies.map((c: any, index: number) => {
                      return (
                        <option value={c.label} key={c.id}>
                          {c.label}
                        </option>
                      );
                    })}
                  </select>
                </CFormGroup>
                {/* <CFormGroup>
                  <CLabel htmlFor="plan.type">Plan</CLabel>
                  <select
                    className="form-control"
                    disabled={isView}
                    name="plan.type"
                    value={form.data?.plan?.type ?? "STANDARD"}
                    onChange={(event: any) => {
                      onChangeHandler(event);
                    }}
                  >
                    <option value={""} key={""}>
                      None
                    </option>
                    {["CUSTOMIZED", "FREE", "STANDARD", "PREMIUM"].map(
                      (c: any, index: number) => {
                        return (
                          <option value={c} key={c}>
                            {c}
                          </option>
                        );
                      }
                    )}
                  </select>
                </CFormGroup> */}
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="zipcode">Zip Code</CLabel>
                  <CInput
                    disabled={isView}
                    type="text"
                    name="zipcode"
                    value={form.data?.zipcode ? form.data?.zipcode : ""}
                    onChange={onChangeHandler}
                    placeholder="ie 12345"
                  />
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="max_agents">Max Agents</CLabel>
                  <CInput
                    disabled={isView}
                    type="number"
                    name="max_agents"
                    value={form.data?.max_agents ? form.data?.max_agents : ""}
                    onChange={onChangeHandler}
                  />
                </CFormGroup>
              </CCol>
            </CRow>
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="primary"
            hidden={isView}
            onClick={() => {
              if (isEdit) {
                editCompany(form, [["id", form.data._id]], (response) => {
                  if (response.status === 200) {
                    setIsEdit(false);
                    setShowModal(false);
                  }
                });
              } else {
                addCompany(form.data, (response) => {
                  if (response.status === 200) {
                    setIsEdit(false);
                    setShowModal(false);
                  }
                });
                // create company api
              }
            }}
          >
            {isEdit ? "Edit Company" : "Create Company"}
          </CButton>
          <CButton
            color="secondary"
            onClick={() => {
              setShowModal(false);
              setIsEdit(false);
            }}
          >
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    );
  }, [
    isEdit,
    // data,
    // focused,
    // setFocused,
    governorates,
    currencies,
    measureUnits,
    isView,
    showModal,
    toggle,
    form,
    addCompany,
    editCompany,
    _renderSelectCountry,
    _renderSelectCountryIP,
    onChangeHandler,
    handleNav,
    adminsListArray,
    handleActiveUsersList,
    handleNavToLeads,
    handleNavToProps
  ]);

  const _renderRemoveModal = React.useMemo(() => {
    return (
      <CModal size="lg" show={showRemoveModal} onClose={toggleRemoveModal}>
        <CModalHeader closeButton>Warning</CModalHeader>
        <CModalBody>
          <CLabel htmlFor="nf-mobile_phone">
            Are you sure you want to remove this company?
          </CLabel>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={toggleRemoveModal}
            variant="outline"
          >
            No
          </CButton>
          <CButton color="primary" onClick={removeBrokerCall} variant="outline">
            Yes
          </CButton>
        </CModalFooter>
      </CModal>
    );
  }, [toggleRemoveModal, showRemoveModal, removeBrokerCall]);
  const _renderMain = React.useMemo(() => {
    if (loadingCompanies) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", height: "100%" }}
        >
          <CSpinner
            color="#8C03F9"
            grow
            style={{ height: "4rem", width: "4rem", alignSelf: "center" }}
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        <CRow alignHorizontal="start" alignVertical="start">
          <CCol xl="4">
            <CWidgetIcon
              text="New Companies last month"
              header={(info.newCompany as unknown) as string | undefined}
              color="primary"
            >
              <CIcon name={"cilSettings"} size={"xl"} />
            </CWidgetIcon>
          </CCol>
          <CCol xl="4">
            <CWidgetIcon
              text="Total Companies"
              header={(info.total as unknown) as string | undefined}
              color="primary"
            >
              <CIcon name={"cilSettings"} size={"xl"} />
            </CWidgetIcon>
          </CCol>
          <CCol xl="4">
            <CWidgetIcon
              text="Total Income"
              header={(info.totalIncome as unknown) as string | undefined}
              color="primary"
            >
              <CIcon name={"cilSettings"} size={"xl"} />
            </CWidgetIcon>
          </CCol>
        </CRow>
        <CCard>
          <CCardHeader className="d-flex align-items-center justify-content-between">
            <CCardTitle>Broker Companies</CCardTitle>
            <CButton
              color="primary"
              variant="outline"
              shape="square"
              size="md"
              style={{
                justifySelf: "flex-end",
                alignItems: "flex-end",
              }}
              onClick={() => {
                toggle();
                let temp = adminsListOnly;
                temp.sort(function (a, b) {
                  //@ts-ignore
                  let textA = a.email.toUpperCase();
                  //@ts-ignore
                  let textB = b.email.toUpperCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                });
                setAdminsListArray(temp);
              }}
            >
              Add New Company
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              fields={fields}
              columnFilter
              tableFilter
              loading={loadingCompanies}
              hover
              sorter
              itemsPerPage={10}
              pagination
              itemsPerPageSelect={{
                values: [10, 15, 20, 30, 40, 50],
              }}
              items={companies}
              scopedSlots={{
                actions: (item: any) => (
                  <td className="py-2">
                    <CButtonGroup
                      color="primary"
                      shape="square"
                      size="sm"
                      onClick={() => {}}
                    >
                      <CButton
                        size="sm"
                        className="btn-info mr-1"
                        onClick={() => {
                          //@ts-ignore
                          const company: any = data.find(
                            (d: any) => d?.data?._id === item._id
                          );
                          let temporaryAgentUsers = company?.agentUsers?.filter(
                            (item: any) => {
                              return item.role !== "Admin";
                            }
                          );
                          //sorting users ALPHA
                          let temp = temporaryAgentUsers;
                          temp.sort(function (a: any, b: any) {
                            //@ts-ignore
                            let textA = a.email.toUpperCase();
                            //@ts-ignore
                            let textB = b.email.toUpperCase();
                            return textA < textB ? -1 : textA > textB ? 1 : 0;
                          });
                          temporaryAgentUsers = temp;

                          let temporaryAdmins = company?.agentUsers?.filter(
                            (item: any) => {
                              return item.role === "Admin";
                            }
                          );
                          //sorting Admins ALPHA
                          let tempAdmins = temporaryAdmins;
                          tempAdmins.sort(function (a: any, b: any) {
                            //@ts-ignore
                            let textA = a.email.toUpperCase();
                            //@ts-ignore
                            let textB = b.email.toUpperCase();
                            return textA < textB ? -1 : textA > textB ? 1 : 0;
                          });
                          temporaryAdmins = tempAdmins;
                          setAdminsListArray(temporaryAdmins);
                          const newObj = {
                            ...company,
                            admin_id: company?.data?.admin_id
                              ? company.data.admin_id
                              : "None",
                            country_id: company?.data?.country_id?.id
                              ? company.data.country_id.id
                              : "None",
                            governrate_id: company?.data?.governrate_id
                              ? company.data.governrate_id
                              : "None",
                            agentUsers: temporaryAgentUsers,
                            Admins: temporaryAdmins,
                          };
                          setForm(newObj);
                          setIsView(true);
                          setIsEdit(false);
                          setShowModal(true);
                        }}
                      >
                        {/* <CIcon name="cil-eye" /> */}
                        <FaEye />
                      </CButton>
                      <CButton
                        size="sm"
                        className="btn-primary mr-1"
                        onClick={() => {
                          const company: any = data.find(
                            (d: any) => d?.data?._id === item._id
                          );
                          let temporaryAgentUsers = company?.agentUsers?.filter(
                            (item: any) => {
                              return item.role !== "Admin";
                            }
                          );
                          //sorting users ALPHA
                          let temp = temporaryAgentUsers;
                          temp.sort(function (a: any, b: any) {
                            //@ts-ignore
                            let textA = a.email.toUpperCase();
                            //@ts-ignore
                            let textB = b.email.toUpperCase();
                            return textA < textB ? -1 : textA > textB ? 1 : 0;
                          });
                          temporaryAgentUsers = temp;
                          let temporaryAdmins = company?.agentUsers?.filter(
                            (item: any) => {
                              return item.role === "Admin";
                            }
                          );
                          //sorting Admins ALPHA
                          let tempAdmins = temporaryAdmins;
                          tempAdmins.sort(function (a: any, b: any) {
                            //@ts-ignore
                            let textA = a.email.toUpperCase();
                            //@ts-ignore
                            let textB = b.email.toUpperCase();
                            return textA < textB ? -1 : textA > textB ? 1 : 0;
                          });
                          temporaryAdmins = tempAdmins;
                          setAdminsListArray(temporaryAdmins);
                          const newObj = {
                            ...company,
                            admin_id: company?.data?.admin_id
                              ? company.data.admin_id
                              : "None",
                            country_id: company?.data?.country_id?.id
                              ? company.data.country_id.id
                              : "None",
                            governrate_id: company?.data?.governrate_id
                              ? company.data.governrate_id
                              : "None",
                            agentUsers: temporaryAgentUsers,
                            Admins: temporaryAdmins,
                          };
                          setForm(newObj);
                          setIsEdit(true);
                          setIsView(false);
                          setShowModal(true);
                        }}
                      >
                        <CIcon name="cil-pencil" />
                      </CButton>
                      <CButton
                        size="sm"
                        className="btn-danger mr-1"
                        onClick={() => {
                          toggleRemoveModal(item._id);
                        }}
                      >
                        <CIcon name="cil-trash" />
                      </CButton>
                    </CButtonGroup>
                  </td>
                ),
              }}
            />
          </CCardBody>
        </CCard>
      </React.Fragment>
    );
  }, [
    fields,
    companies,
    loadingCompanies,
    info,
    data,
    toggleRemoveModal,
    toggle,
    adminsListOnly,
  ]);
  return (
    <div>
      {_renderMain}
      {_renderModal}
      {_renderRemoveModal}
    </div>
  );
};
export default BrokerCompanies;
