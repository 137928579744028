//@ts-nocheck
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CDataTable,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CWidgetIcon,
  CSpinner,
  CSelect,
} from "@coreui/react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import GetPromoHook from "./hooks/usePromoCodeHook";

const { useEffect, useCallback, useMemo } = React;
const discountTypes = [
  {
    value: "percent",
    label: "Percentage",
  },
  {
    value: "amount",
    label: "Amount",
  },
];
interface PromoCodeInterface {
  state: boolean;
  _id: number;
  code: string;
  discount_value: number;
  discount_Type: typeof discountTypes;
  valid_until: string;
  limitCount: number;
}
const fields = [
  { key: "code", label: "PromoCode", _style: { width: "15%" } },
  { key: "discountType", label: "Discount Type", _style: { width: "15%" } },
  {
    key: "discountValue",
    label: "Discount Value",
    _style: { width: "15%" },
  },
  { key: "valid_until", label: "Valid Until", _style: { width: "15%" } },
  { key: "status", label: "Status", _style: { width: "15%" } },
  { key: "usageCount", label: "Used Count", _style: { width: "15%" } },
  {
    key: "actions",
    label: "",
    _style: { width: "5%" },
    sorter: false,
    filter: false,
  },
];

// PromoCode - Discount Type - Discount Value - Valid From - Valid To - Status - Limit Count

const PromoCodes = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditModal, setEditShowModal] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [EditPromo, setEditPromo] = useState();
  const [AddPromo, setAddPromo] = useState();
  const [eddittedId, setEdittedId] = useState("");
  const {
    handleData,
    loading,
    total,
    promoCodes,
    removePromo,
    patchPromo,
    addPromo,
  } = GetPromoHook();
  const toggle = () => setShowModal((toggle) => !toggle);
  const toggleEdit = useCallback(
    (item: any) => {
      if (item) {
        let obj = {
          code: item?.code,
          discount_value: item?.discountValue,
          discount_Type: item?.discountType,
          usage: item?.limitCount,
          state: item?.state,
          valid_until: item.valid_until,
        };
        setEdittedId(item?.id);
        setEditPromo(obj);
      }
      setEditShowModal((toggle) => !toggle);
    },
    [setEditPromo, setEditShowModal]
  );



  const obj = {
    values: [10, 15, 20, 30, 40, 50],
  };
  useEffect(() => {
    handleData();
    // eslint-disable-next-line
  }, []);
  const setDiscountType = useCallback(
    (e) => {
      let selectedValue = document.getElementById("list").value;
      setEditPromo((oldState: any) => ({
        ...oldState,
        discount_Type: selectedValue,
      }));
    },
    [setEditPromo]
  );
  const setDiscountTypeAdd = useCallback(
    (e) => {
      let selectedValue = document.getElementById("listAdd").value;
      setAddPromo((oldState: any) => ({
        ...oldState,
        discount_Type: selectedValue,
      }));
    },
    [setAddPromo]
  );

  const handleChange = React.useCallback(
    (name: string) => ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      setEditPromo((oldState: any) => ({
        ...oldState,
        [name]: value,
      }));
    },
    [setEditPromo]
  );
  const toggleRemoveModal = useCallback(
    (item) => {
      setShowRemoveModal((toggle) => !toggle);
      setEdittedId(item);
    },
    [setShowRemoveModal, setEdittedId]
  );

  const addPromoCall = useCallback(() => {
    addPromo(AddPromo);
    toggle();
    // eslint-disable-next-line
  }, [AddPromo, toggle]);
  const removePromoCall = useCallback(() => {
    removePromo(eddittedId);
    toggleRemoveModal();
  }, [removePromo, toggleRemoveModal, eddittedId]);

  const handleChangeAddPromo = React.useCallback(
    (name: string) => ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      if ((name === "code" && !value.includes(" ")) || name !== "code")
        setAddPromo((oldState: any) => ({
          ...oldState,
          [name]: value,
        }));
      if (name === "remind_date") {
        setAddPromo((oldState: any) => ({
          ...oldState,
          [name]: value,
        }));
      }
    },
    [setAddPromo]
  );
  // const handleChangeAddPromoInDate = React.useCallback(
  //   (name: string) => ({
  //     target: { value },
  //   }: React.ChangeEvent<HTMLInputElement>) => {
  //     console.log(value, "VALUE");
  //     if (name === "remind_date") {
  //       setAddPromo((oldState: any) => ({
  //         ...oldState,
  //         [name]: value,
  //       }));
  //     }
  //     console.log(AddPromo);
  //   },
  //   [setAddPromo, AddPromo]
  // );
  const HandleLoading = useMemo(() => {
    if (loading) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", height: "100%" }}
        >
          <CSpinner
            color="#8C03F9"
            grow
            style={{ height: "4rem", width: "4rem", alignSelf: "center" }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <CRow alignHorizontal="start" alignVertical="start">
            <CCol xl="6">
              <CWidgetIcon
                text="Total PromoCodes"
                header={total}
                color="primary"
              >
                <CIcon name={"cilSettings"} size={"xl"} />
              </CWidgetIcon>
            </CCol>
            <CCol xl="6">
              <CWidgetIcon text="PromoCodes Used" header="2" color="primary">
                <CIcon name={"cilSettings"} size={"xl"} />
              </CWidgetIcon>
            </CCol>
          </CRow>
          <CCard>
            <CCardHeader className="d-flex align-items-center justify-content-between">
              <CCardTitle>PromoCodes</CCardTitle>
              <CButton
                color="primary"
                variant="outline"
                shape="square"
                size="md"
                style={{
                  justifySelf: "flex-end",
                  alignItems: "flex-end",
                }}
                onClick={toggle}
              >
                Add PromoCode
              </CButton>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                fields={fields}
                columnFilter
                tableFilter
                hover
                sorter
                itemsPerPage={10}
                pagination
                itemsPerPageSelect={obj}
                items={promoCodes}
                scopedSlots={{
                  actions: (item: { id: string }) => (
                    <td className="py-2">
                      <CButtonGroup
                        color="primary"
                        shape="square"
                        size="sm"
                        onClick={() => {}}
                      >
                        <CButton size="sm" className="btn-primary mr-1">
                          <CIcon
                            name="cil-pencil"
                            onClick={() => {
                              toggleEdit(item);
                            }}
                          />
                        </CButton>
                        <CButton
                          size="sm"
                          className="btn-danger mr-1"
                          onClick={() => {
                            toggleRemoveModal(item.id);
                          }}
                        >
                          <CIcon name="cil-trash" />
                        </CButton>
                      </CButtonGroup>
                    </td>
                  ),
                }}
              />
            </CCardBody>
          </CCard>
          <CModal size="lg" show={showModal} onClose={toggle}>
            <CModalHeader closeButton>Add New PromoCode</CModalHeader>
            <CModalBody>
              <CForm action="" method="post">
                <CFormGroup>
                  <CLabel htmlFor="nf-pc">PromoCode</CLabel>
                  <CInput
                    id="nf-pc"
                    name="nf-pc"
                    placeholder="Enter PromoCode ..."
                    value={AddPromo?.code}
                    onChange={handleChangeAddPromo("code")}
                  />
                </CFormGroup>
                <CRow>
                  <CCol>
                    <CFormGroup>
                      <CLabel htmlFor="nf-discountType">Discount Type</CLabel>
                      <CSelect
                        id="listAdd"
                        name="nf-discountType"
                        value={addPromo?.discountType}
                        onChange={setDiscountTypeAdd}
                      >
                        <option selected>Open this select menu</option>
                        <option value="percent">Percentage</option>
                        <option value="amount">Amount</option>
                      </CSelect>
                    </CFormGroup>
                  </CCol>
                  <CCol>
                    <CFormGroup>
                      <CLabel htmlFor="nf-discountValue">Discount Value</CLabel>
                      <CInput
                        type="number"
                        id="nf-discountValue"
                        name="nf-discountValue"
                        placeholder="Add Discount Value ..."
                        value={AddPromo?.discount_value}
                        onChange={handleChangeAddPromo("discount_value")}
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CFormGroup>
                      <CLabel htmlFor="nf-limit">Limit Count</CLabel>
                      <CInput
                        type="number"
                        id="nf-limit"
                        name="nf-limit"
                        placeholder="Add Limit Count ..."
                        value={AddPromo?.usage}
                        onChange={handleChangeAddPromo("usage")}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol>
                    <CLabel htmlFor="nf-limit">Valid For</CLabel>
                    <Calendar
                      selectRange={false}
                      locale={"US"}
                      calendarType={"Arabic"}
                      onChange={(e) => {
                        setAddPromo((oldState: any) => ({
                          ...oldState,
                          valid_until: e.toLocaleDateString(),
                        }));
                      }}
                    />
                  </CCol>
                </CRow>
              </CForm>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="primary"
                variant="outline"
                onClick={() => {
                  addPromoCall();
                }}
              >
                Add Promo Code
              </CButton>
              <CButton color="secondary" onClick={toggle} variant="outline">
                Cancel
              </CButton>
            </CModalFooter>
          </CModal>

          {/* 
          /// Modal for showing the edit form
          */}
          <CModal size="lg" show={showEditModal} onClose={toggleEdit}>
            <CModalHeader closeButton>Update Your Promo Code</CModalHeader>
            <CModalBody>
              <CForm action="" method="post">
                <CFormGroup>
                  <CLabel htmlFor="nf-pc">Promo Code</CLabel>
                  <CInput
                    id="nf-pc"
                    name="nf-pc"
                    placeholder={""}
                    value={EditPromo?.code}
                    onChange={handleChange("code")}
                  />
                </CFormGroup>
                <CRow>
                  <CCol>
                    <CFormGroup>
                      <CLabel htmlFor="nf-discountType">Discount Type</CLabel>
                      <CSelect
                        id="list"
                        name="nf-discountType"
                        value={EditPromo?.discount_Type}
                        onChange={setDiscountType}
                      >
                        <option selected>Open this select menu</option>
                        <option value="percentage">Percentage</option>
                        <option value="amount">Amount</option>
                      </CSelect>
                    </CFormGroup>
                  </CCol>
                  <CCol>
                    <CFormGroup>
                      <CLabel>Discount Value</CLabel>
                      <CInput
                        placeholder="Add Discount Value ..."
                        value={EditPromo?.discount_value}
                        onChange={handleChange("discount_value")}
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CFormGroup>
                      <CLabel htmlFor="nf-limit">Limit Count</CLabel>
                      <CInput
                        type="number"
                        id="nf-limit"
                        name="limitCount"
                        placeholder="Add Limit Count ..."
                        value={EditPromo?.usage}
                        onChange={handleChange("usage")}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol>
                    <CFormGroup>
                      <CLabel htmlFor="nf-validity_date">Validity Date</CLabel>
                      <Calendar
                        selectRange={false}
                        locale={"US"}
                        calendarType={"Arabic"}
                         
                        value={
                          EditPromo?.valid_until ? new Date(EditPromo.valid_until) : ""
                        }
                        onChange={(e) => {
                          setEditPromo((oldState: any) => ({
                            ...oldState,
                            valid_until: e.toLocaleDateString(),
                          }));
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
              </CForm>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="primary"
                variant="outline"
                onClick={() => {
                  patchPromo(EditPromo, eddittedId);
                  toggleEdit();
                }}
              >
                Edit PromoCode
              </CButton>
              <CButton color="secondary" onClick={toggleEdit} variant="outline">
                Cancel
              </CButton>
            </CModalFooter>
          </CModal>

          <CModal size="lg" show={showRemoveModal} onClose={toggleRemoveModal}>
            <CModalHeader closeButton>Warning</CModalHeader>
            <CModalBody>
              <CLabel htmlFor="nf-mobile_phone">
                Are you sure you want to remove this Promo Code?
              </CLabel>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="secondary"
                onClick={toggleRemoveModal}
                variant="outline"
              >
                No
              </CButton>
              <CButton
                color="primary"
                onClick={removePromoCall}
                variant="outline"
              >
                Yes
              </CButton>
            </CModalFooter>
          </CModal>
        </div>
      );
    }
    // eslint-disable-next-line
  }, [
    total,
    loading,
    promoCodes,
    showModal,
    EditPromo,
    handleChange,
    showEditModal,
    addPromo,
    AddPromo,
    eddittedId,
    handleChangeAddPromo,
    patchPromo,
    toggleEdit,
    removePromoCall,
    showRemoveModal,
    toggleRemoveModal,
  ]);

  return <div>{HandleLoading}</div>;
};

export default PromoCodes;
