import { useContext, useEffect, useState, useCallback } from "react";
import { dateSplitter } from "../../../utilis/objectHandlers";
import { AxiosResponse } from "axios";
import {
  get,
  axiosPatchWithToken,
  axiosPostWithToken,
  remove,
} from "../../../utilis/requests";
import userContext from "../../../userStore/context";

const useGetProperties = () => {
  const { userState } = useContext(userContext);
  const [userTotal, setUserTotal] = useState("0");
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState();
  const [companies, setCompanies] = useState([]);
  const [activeUsers, setActiveUsers] = useState(0);
  const [newUsers, setNewUsers] = useState(0);
  const [error, setError] = useState<string | null>();
  const [showModal, setShowModal] = useState(false);

  let temp = {};
  useEffect(() => {
    handleUserData();
    // eslint-disable-next-line
  }, [userState.token]);
  const handleUserData = async () => {
    setLoadingUsers(true);
    setLoading(true);
    try {
      const response: AxiosResponse = await get(
        `/superadmin/brokercompany`,
        userState.token
      );

      if (response.status === 200) {
        const tempCompanies: {}[] = [];
        response.data.data.forEach((item: any) => {
          temp = {
            value: item.data?._id? item.data._id : "none",
            label: item.data?.name? item.data.name: "none" ,
          };
          tempCompanies.push(temp);
        });
        //@ts-ignore
        setCompanies(tempCompanies);
      }
    } catch (error) {
      setLoading(false);
      return;
    }
    try {
      const response: AxiosResponse = await get(
        `/superadmin/user`,
        userState.token
      );
      if (response.status === 200) {
        setLoadingUsers(false);
        setUserTotal(response.data.total);
        setActiveUsers(response.data.totalActive);
        setNewUsers(response.data.totalAdded);
        // eslint-disable-next-line
        const list = response.data.data.map((item: any) => ({
          id: item?._id,
          name: item?.name ? item?.name : "None",
          number: item?.number ?? "",
          role:item?.role? item.role : "None",
          email: item?.email ? item?.email : "None",
          admin: item?.userAdmin?.email,
          brokerCompany: item?.companyName ? item?.companyName.name : "None",
          brokerCompanyID: item?.companyName ? item?.companyName._id : "None",
          propertiesAdded: item?.propertyAdded,
          joinedAt: dateSplitter(item?.createdAt),
          leadsAdded: item?.leadsAdded,
        }));
        //@ts-ignore
        setUsers(list);
      } else {
        setLoading(false);
        if (response.status !== 401)
        setLoadingUsers(false)
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };
  const submitNewUserData = useCallback(
    async (payload: any) => {
      setLoading(true);
      try {
        const response: AxiosResponse = await axiosPostWithToken(
          `/superadmin/user`,
          payload,
          userState.token
        );
        if (response.status === 200) {
          const item = response.data.data[response.data.data.length - 1];
          // eslint-disable-next-line
          temp = {
            id: item._id,
            name: item.name ? item.name : "None",
            number: item.number,
            email: item.email,
            brokerCompany: item.companyName ? item.companyName.name : "None",
            brokerCompanyID: item.companyName ? item.companyName._id : "None",
            propertiesAdded: item.propertyAdded,
            leadsAdded: item.leadsAdded,
          };
          if (users) {                                            
            let newUsers = users;
            //@ts-ignore
            newUsers.push(temp);
            setUsers(newUsers);
          }
          setUserTotal(response.data.total);
          handleUserData();
          setShowModal(false);
          setLoading(false);
          setError(null);
        } else {
          setLoading(false);
          return;
        }
      } catch (error) {
        if (error?.response?.data?.message.includes("phone"))
          setError("Phone number is already registered");
        if (error?.response?.data?.message.includes("email"))
          setError("Email is already registered");
        setLoading(false);
        return;
      }
    },
    [
      handleUserData,
      setUserTotal,
      setShowModal,
      setLoading,
      setError,
      users,
      userState.token,
      setUsers,
    ]
  );
  const editUserData = async (payload: any, userID: string) => {
    setLoading(true);
    try {
      const response: AxiosResponse = await axiosPatchWithToken(
        `/superadmin/user`,
        payload,
        userState.token,
        [["userId", userID]]
      );
      if (response.status === 200) {
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };
  const deleteUser = async (userID: string) => {
    setLoading(true);
    try {
      const response: AxiosResponse = await remove(
        `/superadmin/user?userId=${userID}`,
        userState.token
      );
      if (response.status === 200) {
        let x = parseInt(userTotal) - 1;
        //@ts-ignore
        const filteredItems = users.filter((item: any) => item.id !== userID);
        setUsers(filteredItems);
        setUserTotal(x.toString());
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };
  return {
    userTotal,
    loading,
    loadingUsers,
    handleUserData,
    users,
    submitNewUserData,
    editUserData,
    deleteUser,
    companies,
    activeUsers,
    error,
    setError,
    newUsers,
    showModal,
    setShowModal,
    userState,
  };
};

export default useGetProperties;
